/** @jsx jsx */
// @ts-ignore
import { useCallback } from 'react'
import { jsx, Grid, Box } from 'theme-ui'
import {
  CollectionTemplateType,
  Maybe,
  ProductItemComponentType,
  ProductVariantType,
} from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import ProductItem from '~/components/product-item'
import CollectionProducts from '~/containers/collection-products'
import { isCircularRug, isRunnerRug } from '~/utils/product-variant'

import { buildLastChanceProducts } from '../../gatsby/util'
import { graphql, useStaticQuery } from 'gatsby'

// const lastChanceQuery = graphql`
//   fragment Images on ProductImage {
//     altText
//     srcDesktopMedium
//     srcMobile
//     srcMobileSmall
//     srcSmall
//   }
//   query LastChance {
//     lastChance {
//       handle
//       id
//       title
//       description
//       templateKey
//       seo {
//         title
//         description
//       }
//       metafields {
//         customHeading
//         ankleContent
//       }
//       products {
//         title(raw: true)
//         handle
//         productType
//         imagesBySKU {
//           images {
//             ...Images
//           }
//           sku
//         }
//         variants {
//           id
//           sku
//           quantityAvailable
//           product {
//             handle
//           }
//           price {
//             amount
//           }
//           compareAtPrice {
//             amount
//           }
//           selectedOptions {
//             name
//             value
//           }
//           title(type: SIZE)
//           shopifyId2
//           currentlyNotInStock
//           availableForSale
//         }
//         metafields {
//           cardCollectionTag
//         }
//         colorOptions {
//           image
//           imageTip
//           isCustom
//           sku
//           title
//           onSale
//           link
//         }
//         variantImages {
//           image
//           title
//           onSale
//           link
//           imageTip
//           imageSelector
//         }
//         images {
//           ...Images
//         }
//         mainImage {
//           ...Images
//         }
//       }
//     }
//   }
// `
const lastChanceQuery = graphql`
  fragment shopifyImageSrcSetFull on ProductImage {
    originalSrc
    srcLargeDesktop
    srcDesktopMedium
    srcDesktop
    srcMobile
    srcMobileSmall
    srcSmall
    srcXSmall
    altText
  }

  fragment shopifyCollectionItem on ShopifyCollection {
    id
    handle
    description: descriptionHtml
    title
    templateKey
    image {
      originalSrc
    }
    imageResponsive {
      default {
        ...shopifyImageSrcSetFull
      }
      mobile {
        ...shopifyImageSrcSetFull
      }
    }
    products {
      id
      shopifyId
      handle
      title
      productNote
      tags
      productType
      description
      images {
        ...shopifyImageSrcSetFull
      }
      collections {
        handle
      }
      inStock
      metafields {
        origin
        color
        cardCollectionTag
        antiquesRugsColor
        cardCollectionTag
        antiqueStyle
        filterSize
        tearsheet
        antiquesPdfLink
        antiqueName
        rugPileHeight
        rugSize
        rugStyle
        rugColors
        rugDesign
        rugFiber
        shape
      }
      availableForSale
      isRunnerOrCircularRug
      colorName: title(color: true)
      labels {
        newArrival
      }
      cheapestVariant {
        sku
        price {
          amount
        }
        compareAtPrice {
          amount
        }
      }
      highestVariant {
        sku
        price {
          amount
        }
        compareAtPrice {
          amount
        }
      }
      mainImage(type: OVERHEAD, fallback: FRONT) {
        ...shopifyImageSrcSetFull
      }
      mainImageHorizontal: mainImage(type: HORIZONTAL, fallback: FRONT) {
        ...shopifyImageSrcSetFull
      }
      mainImageLifestyle: mainImage(type: LIFESTYLE_ADD, fallback: LIFESTYLE) {
        ...shopifyImageSrcSetFull
      }
      mainImageLifestyleOriginal: mainImage(type: LIFESTYLE, fallback: LIFESTYLE_ADD) {
        ...shopifyImageSrcSetFull
      }
      mainImageFront: mainImage(type: FRONT, fallback: FRONT) {
        ...shopifyImageSrcSetFull
      }
      mainImageRunners: mainImage(type: RUNNERS, fallback: OVERHEAD) {
        ...shopifyImageSrcSetFull
      }
      mainImageCircular: mainImage(type: CIRCULAR, fallback: OVERHEAD) {
        ...shopifyImageSrcSetFull
      }
      colorOptions {
        title
        image
        imageTip
        link
        onSale
      }
      imagesBySKU {
        sku
        images {
          ...shopifyImageSrcSetFull
        }
      }
      colorOptionsFinish: colorOptions(option: FINISH) {
        title
        image
        imageTip
        link
        onSale
      }
      variantImages {
        title
        image
        imageTip
        imageSelector
        link
        onSale
      }
      variants {
        id
        sku
        shopifyId
        shopifyId2
        title
        availableForSale
        currentlyNotInStock
        quantityAvailable
        price {
          amount
        }
        image {
          altText
          id
          originalSrc
          srcLargeDesktop
          srcDesktopMedium
          srcDesktop
          srcMobile
          srcMobileSmall
          srcSmall
          srcXSmall
        }
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions(normalizeValue: true) {
          name
          value
        }
      }
    }
  }

  query AllCollections {
    lastChanceQueryTest: shopifyCollection(handle: { eq: "last-chance-rugs" }) {
      metafields {
        ankleContent
        activeFilters
        onlyCompareAtPriceVariants
        sizeFilter
        colorFilter
        fiberFilter
        designFilter
        pileHeightFilter
        showPrize
        customHeading
        styleFilter
        shapeFilter
        noindex
      }
      seo {
        description
        title
      }
      ...shopifyCollectionItem
    }
  }
`

interface Images {
  altText: string
  srcDesktopMedium: string
  srcMobile: string
  srcMobileSmall: string
  srcSmall: string
}

type QueryResult = {
  lastChance: {
    handle: string
    id: string
    title: string
    description: string
    templateKey: string
    seo: {
      title: string
      description: string
    }
    metafields: {
      customHeading: string
      ankleContent: string
    }
    products: {
      title: string
      handle: string
      productType: string
      variants: ProductVariantType[]
      metafields: {
        cardCollectionTag: string
      }
      colorOptions: {
        image: string
        imageTip: string
        isCustom: boolean
        sku: string
        title: string
        onSale: boolean
        link: string
      }[]
      variantImages: {
        image: string
        title: string
        onSale: boolean
        link: string
        imageTip: string
        imageSelector: string
      }[]
      images: Images[]
      mainImage: Images
    }[]
  }[]
}

type Props = CollectionTemplateType

const CollectionLastChance = ({ pageContext: { data } }: Props) => {
  // const _data = useStaticQuery<QueryResult>(lastChanceQuery)

  if (!data) return null

  // const parsedProducts = buildLastChanceProducts(
  //   _data?.lastChanceQueryTest.products.filter(product => product.title.includes('Oro'))
  // )
  // console.log('parsedProducts', parsedProducts)

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      heading={data.metafields?.customHeading}
      description={data.seo?.description || data.description}
      ankleContent={data.metafields?.ankleContent}
      handle={data.handle}
      // heroHeader={{
      //   isExternal: true,
      //   ...(data.imageResponsive && {
      //     imageSet: data.imageResponsive,
      //   }),
      // }}
    >
      <CollectionProducts
        data-template="collection"
        initialData={data.products}
        collectionHandle={data.handle}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-lastChance"
        // collectionProducts={parsedProducts}
      />
    </CollectionLayout>
  )
}

export default CollectionLastChance
